<script>
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'

export default {
  name: 'rich-input-mentions',
  components: {
    BaseAvatar,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedIndex: 0,
    }
  },
  computed: {
    list() {
      const filter = this.filter && this.filter.toLowerCase()

      return this.users.filter(
        (user) => !filter || user.fullName.toLowerCase().includes(filter)
      )
    },
  },
  watch: {
    filter() {
      this.selectedIndex = 0
    },
    selectedIndex() {
      const element = this.$refs[`mention-${this.selectedIndex}`]

      if (element && element[0]) this.$el.scrollTo(0, element[0].offsetTop)
    },
  },
  mounted() {
    document.addEventListener('keydown', (e) => {
      let prevIndex, nextIndex

      switch (e.which) {
        case 38:
          prevIndex = this.selectedIndex - 1

          this.selectedIndex = prevIndex < 0 ? this.list.length - 1 : prevIndex
          e.preventDefault()
          break

        case 40:
          nextIndex = this.selectedIndex + 1

          this.selectedIndex = nextIndex > this.list.length - 1 ? 0 : nextIndex
          e.preventDefault()
          break
      }
    })

    document.addEventListener('keyup', (e) => {
      switch (e.which) {
        case 13:
          this.onSelect()
          e.preventDefault()
          break
      }
    })
  },
  methods: {
    onSelect(idx = this.selectedIndex) {
      const user = this.list[idx]

      if (user) {
        this.$emit('select', {
          name: user.fullName,
          uuid: user.uuid,
          id: user.id,
        })
      }
    },
  },
}
</script>

<template>
  <div
    class="my-[5px] flex h-40 w-64 flex-col overflow-auto rounded bg-white shadow-sm"
  >
    <template v-if="list.length">
      <div
        v-for="(user, idx) in list"
        :ref="`mention-${idx}`"
        :key="idx"
        class="flex min-h-[45px] cursor-pointer items-center px-[15px] py-[5px] hover:bg-blue-100"
        :class="{
          'bg-primary text-white': selectedIndex === idx,
        }"
        @click="onSelect(idx)"
      >
        <base-avatar
          size="xs"
          class="mr-2.5"
          :abbr="user.initials"
          :src="user.avatarUrl"
        />
        <span class="truncate">
          {{ user.fullName }}
        </span>
      </div>
    </template>
    <div
      v-else
      class="flex flex-1 items-center justify-center text-fg-disabled"
    >
      Not found
    </div>
  </div>
</template>
