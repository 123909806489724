<script setup lang="ts">
import { ValidationProvider } from 'vee-validate'
import { computed, onMounted, ref, watch } from 'vue'
import { directive as vClickaway } from 'vue-clickaway'
import ui from '/~/core/ui'
import CreatorFile from '/~rec/components/post/creator/attachments/creator-file.vue'
import CreatorGif from '/~rec/components/post/creator/attachments/creator-gif.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseEmojis from '/~/components/base/emojis/base-emojis.vue'
import BaseGifs from '/~/components/base/gifs/base-gifs.vue'
import Popup from '/~/components/base/select/popup'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import BaseUploader from '/~/components/base/uploader/base-uploader.vue'
import RichAttachmentsPopup from './rich-attachments-popup.vue'
import RichInputMentions from './rich-input-mentions.vue'
import { useInput } from '/~/composables/base/use-input'

const androidMentions = new RegExp(/^@([\s\S]+?)#\w{5}#| @([\s\S]+?)#\w{5}#/g)

const props = withDefaults(
  defineProps<{
    // BaseInputProps
    error?: string | string[]
    disabled?: boolean
    validation?: Record<string, any>
    // Component specific props
    content?: string
    attachments?: any[]
    rows?: number
    maxRows?: number
    lineHeight?: number
    focused?: boolean
    attachmentsPosition?: 'top' | 'bottom' | 'popup'
    loading?: boolean
    plain?: boolean
    disableSend?: boolean
    mentionUsers?: any[] | null
    maxLength?: number
    isPost?: boolean
    centerActions?: boolean
    isAndriod?: boolean
    mentioned?: any[]
    isOffensiveWarningShow?: boolean
  }>(),
  {
    // BaseInputProps defaults
    error: '',
    disabled: false,
    validation: () => ({}),
    // Component-specific defaults
    content: '',
    attachments: () => [],
    rows: 1,
    maxRows: 6,
    lineHeight: 20,
    focused: false,
    attachmentsPosition: 'bottom',
    loading: false,
    plain: false,
    disableSend: false,
    mentionUsers: null,
    maxLength: 0,
    isPost: false,
    centerActions: false,
    isAndriod: false,
    mentioned: () => [],
    isOffensiveWarningShow: false,
  }
)

const emit = defineEmits([
  'input',
  'update:content',
  'update:attachments',
  'update:mentioned',
  'send',
])

const { validationProviderRef, isFocused, id, validate, onFocus, onBlur } =
  useInput(props, emit)

const cursorCurrentNode = ref<any>(null)
const multiline = ref(false)
const mention = ref<string | null>(null)
const cleanContent = ref<string | null>(null)
const oldContent = ref(props.content)
const inputType = ref<string | null>(null)

const inputRef = ref<HTMLElement | null>(null)
const attachRef = ref<any>(null)
const cameraRef = ref<any>(null)
const emojisRef = ref<any>(null)
const gifsRef = ref<any>(null)
const attachmentsPopupRef = ref<any>(null)

const files = computed(() =>
  props.attachments.filter((a: any) => a.type !== 'image/gif')
)
const gifs = computed(() =>
  props.attachments.filter((a: any) => a.type === 'image/gif')
)
const isSendDisabled = computed(
  () => props.loading || props.disableSend || props.disabled
)
const isShowPlaceholder = computed(
  () => props.plain || (!props.plain && !props.content && !isFocused.value)
)
const getPlaceholderStyle = computed(() => {
  if (props.plain && (isFocused.value || props.content)) {
    return ui.desktop ? 'top:-15px' : 'top: -13px'
  }
  return null
})
const firstError = computed(
  () => validationProviderRef.value?.errors[0] || props.error
)

watch(
  () => props.content,
  (val) => {
    if (props.isAndriod) return
    if (inputRef.value && val !== inputRef.value.innerHTML) {
      inputRef.value.innerHTML = props.content
      setCursorToEnd()
    }
    if (val) {
      parseMentions()
    }
    if (inputRef.value) {
      cleanContent.value = inputRef.value.innerText
      validate(cleanContent.value)
      emit('input')
      multiline.value = inputRef.value.clientHeight > props.lineHeight + 20
    }
  }
)

onMounted(() => {
  if (props.content && !props.isAndriod && inputRef.value) {
    inputRef.value.innerHTML = props.content
    setCursorToEnd()
  }
  if (props.focused) {
    focus()
  }
})

function setCursorToEnd() {
  if (document.createRange && inputRef.value) {
    const range = document.createRange()

    range.selectNodeContents(inputRef.value)
    range.collapse(false)
    const selection = window.getSelection()

    selection?.removeAllRanges()
    selection?.addRange(range)
  }
}

function focus() {
  if (props.isAndriod) return
  onFocus()
  inputRef.value?.focus()
  saveSelection()
}

function onAttachClick() {
  attachRef.value?.show()
}

function onCameraClick() {
  cameraRef.value?.show()
}

function onEmojisClick() {
  emojisRef.value?.show()
}

function onGifsClick() {
  gifsRef.value?.show()
}

function onAddAttachment(attachment: any) {
  emit('update:attachments', [...props.attachments, attachment])
  focus()
}

function onDeleteAttachment(attachment: any) {
  const updated = props.attachments.filter((a: any) => a !== attachment)

  emit('update:attachments', updated)
  if (!updated.length && attachmentsPopupRef.value) {
    attachmentsPopupRef.value.close()
  }
  return updated
}

function saveSelection() {
  const selection = window.getSelection()

  if (selection && selection.rangeCount > 0) {
    cursorCurrentNode.value = {
      anchorOffset: selection.anchorOffset,
      rangeCount: selection.rangeCount,
      anchorNode: selection.anchorNode,
      range: selection.getRangeAt(0),
      selection,
    }
  }
}

function onKeyDown(e: KeyboardEvent) {
  if (props.loading) {
    e.preventDefault()
  }
  if (mention.value && [38, 40, 13].includes(e.keyCode)) {
    e.preventDefault()
    return
  }
  if (e.keyCode === 13) {
    if (e.shiftKey || e.ctrlKey) {
      if (e.ctrlKey) {
        document.execCommand('insertHTML', false, '\n')
      }
      multiline.value = true
    } else {
      e.preventDefault()
    }
  }
}

function updateContent() {
  if (inputRef.value) {
    let content = inputRef.value.innerHTML.replace(/&nbsp;/g, ' ')

    if (content === '\n') content = ''
    emit('update:content', content)
  }
}

function onKeyUp(e: KeyboardEvent) {
  saveSelection()
  detectMention()
  updateContent()
  if (e.keyCode === 13 && !e.shiftKey && !e.ctrlKey && !mention.value) {
    onSend()
  }
}

function matchMentionRegExp(text: string): RegExpMatchArray | null {
  const mentionRegExp = props.isAndriod
    ? new RegExp(
        /(?:^|[^a-zA-Z0-9_＠!@#$%&*])(?:(?:@|＠)(?!\/))([a-zA-Z0-9/_]{1,15})(?:\b(?!@|＠)|$)$/
      )
    : new RegExp(
        /(?:^|[^a-zA-Z0-9_＠!@#$%&*])(?:(?:@|＠)(?!\/))([a-zA-Z0-9/_]{1,15})(?:\b(?!@|＠)|$)/
      )

  return text.match(mentionRegExp)
}

function checkSpaceText(text: string): boolean {
  return /\s/.test(text)
}

function detectMention() {
  if (!props.mentionUsers || !props.mentionUsers.length) return
  const { selection } = cursorCurrentNode.value || {}
  const currentNodeText = selection?.focusNode && selection.focusNode.data
  const cursorPosition = selection?.anchorOffset
  const textUntilCursor = currentNodeText
    ? currentNodeText.substring(0, cursorPosition)
    : ''

  if (!textUntilCursor) return
  checkMentions(textUntilCursor)
}

function onPaste(e: ClipboardEvent) {
  e.preventDefault()
  let text = ''

  if (e.clipboardData && e.clipboardData.getData) {
    text = e.clipboardData.getData('text/plain')
  } else if (
    (window as any).clipboardData &&
    (window as any).clipboardData.getData
  ) {
    text = (window as any).clipboardData.getData('Text')
  }
  document.execCommand('insertHTML', false, text)
}

function onAwayClick() {
  if (mention.value) {
    mention.value = null
  }
}

function onEmojiSelect(emoji: string) {
  if (cursorCurrentNode.value) {
    const emojiTextNode = document.createTextNode(emoji)
    const { range, selection } = cursorCurrentNode.value

    range.insertNode(emojiTextNode)
    range.setEndAfter(emojiTextNode)
    range.collapse(false)
    selection.removeAllRanges()
    selection.addRange(range)
  } else {
    if (inputRef.value) {
      inputRef.value.innerHTML += emoji
      inputRef.value.focus()
    }
  }
  emit('update:content', inputRef.value?.innerHTML)
}

function onSend() {
  if (isSendDisabled.value) return
  emit('send')
}

function onSelectMention(user: any) {
  if (props.isAndriod) {
    onSelectMentionAndroid(user)
    return
  }
  const { anchorNode, range, selection } = cursorCurrentNode.value

  if (anchorNode.textContent !== `${mention.value}`) {
    const result = matchMentionRegExp(anchorNode.textContent)
    const addIdx = result && checkSpaceText(result[0]) ? 1 : 0

    anchorNode.deleteData(result!.index + addIdx, result![0].length - addIdx)
  } else {
    anchorNode.remove()
  }
  const taggedText = document.createTextNode(`@${user.name}`)
  const taggedSpan = document.createElement('span')

  taggedSpan.setAttribute('data-user-id', user.id)
  taggedSpan.setAttribute('contenteditable', 'false')
  taggedSpan.className = 'text-blue-500 font-bold'
  taggedSpan.appendChild(taggedText)
  range.insertNode(document.createTextNode(' '))
  range.insertNode(taggedSpan)
  range.collapse(false)
  selection.removeAllRanges()
  selection.addRange(range)
  updateContent()
  mention.value = null
}

function parseMentions() {
  const result: any[] = []

  if (props.content) {
    const div = document.createElement('div')

    div.innerHTML = props.content
    div.querySelectorAll('span').forEach((elem) => {
      const userId = elem.getAttribute('data-user-id')
      const mentionUser = props.mentionUsers?.find((u: any) => u.id === userId)

      if (mentionUser) {
        result.push(mentionUser.raw)
      }
    })
  }
  emit('update:mentioned', result)
}

function checkMentions(value: string) {
  const result = matchMentionRegExp(value)

  mention.value =
    result && !checkSpaceText(result.input[result.index + result[0].length])
      ? result[1]
      : null
}

function getCaretPos(el: HTMLElement): number {
  el.focus()
  if ((el as HTMLInputElement).selectionStart)
    return (el as HTMLInputElement).selectionStart!
  if (document.selection) {
    const sel = document.selection.createRange()
    const clone = sel.duplicate()

    sel.collapse(true)
    clone.moveToElementText(el)
    clone.setEndPoint('EndToEnd', sel)
    return clone.text.length
  }
  return 0
}

function deleteMentitionFromAka(
  caretPos: number,
  content: string,
  mentioned: any[]
): string {
  let tryDeleteMention = ''
  let checkFlag = false
  const findDeletePosition = (content: string): number => {
    let search = true
    let position = 0
    let currentPosition = 0

    while (search) {
      position = content.indexOf(tryDeleteMention.slice(1), currentPosition)
      if (position === caretPos) {
        search = false
      }
      currentPosition = position + 1
    }
    return position
  }

  for (let i = 0; i <= content.length; i++) {
    if (checkFlag || oldContent.value[i] !== content[i]) {
      checkFlag = true
      tryDeleteMention += oldContent.value[i]
      if (mentioned.find((i: any) => i.name === tryDeleteMention.slice(1))) {
        tryDeleteMention += oldContent.value.slice(i + 1, i + 7)
        break
      }
    }
  }
  if (tryDeleteMention && /@([\s\S]+?)/.test(tryDeleteMention)) {
    const position = findDeletePosition(content)
    const firstPart = content.split('').splice(0, position).join('')
    const secondPart = content
      .split('')
      .splice(position + tryDeleteMention.slice(1).length + 1)
      .join('')
      .trim()
    const newContent = firstPart + secondPart

    emit('update:mentioned', {
      add: false,
      user: mentioned.find((m: any) => m.nanoId === tryDeleteMention.slice(-5)),
    })
    return newContent
  }
  return content
}

function deleteMenthionAndriod(content: string, caretPos: number): string {
  let newContent = content
  const allMentions = [...oldContent.value.matchAll(androidMentions)].map(
    (item) => ({
      mention: item[0].slice(0, -1).trim(),
      index: item.index,
      nanoId: item[0].slice(0, -1).trim().slice(-5),
    })
  )
  const mentioned = props.mentioned.map((item: any) => {
    if (item.name) {
      return {
        name: item.name,
        id: item.id,
        nanoId: item.id.slice(0, 5),
      }
    }
    const { first_name: firstName, last_name: lastName, id } = item

    return {
      name: `${firstName} ${lastName}`,
      id,
      nanoId: id.slice(0, 5),
    }
  })
  let stringDiff = ''

  for (let i = 0; i <= newContent.length; i++) {
    if (oldContent.value[i] !== newContent[i]) {
      stringDiff = oldContent.value[i]
      break
    }
  }
  if (stringDiff === '@') {
    allMentions.forEach((item) => {
      const mayBeMention = newContent.slice(
        caretPos,
        caretPos + item.mention.length + 1
      )

      if (
        item.mention.slice(1) === mayBeMention.trim().slice(0, -1) &&
        item.index! + 1 >= caretPos
      ) {
        newContent = deleteMentitionFromAka(caretPos, content, mentioned)
      }
    })
    return newContent
  }
  const tryDeleteMention = allMentions.find(
    (m) => m.index! < caretPos && m.index! + m.mention.length + 2 >= caretPos
  )

  if (
    tryDeleteMention &&
    newContent
      .slice(tryDeleteMention.index, caretPos)
      .includes(`${tryDeleteMention.mention}# `)
  ) {
    return newContent
  }
  if (tryDeleteMention) {
    const mentionToDelete = mentioned.find(
      (m) => m.nanoId === tryDeleteMention.nanoId
    )
    const firstPart = oldContent.value
      .split('')
      .splice(0, tryDeleteMention.index!)
      .join('')
    const secondPart = oldContent.value
      .split('')
      .splice(tryDeleteMention.index! + tryDeleteMention.mention.length + 2)
      .join('')

    newContent = firstPart + secondPart
    emit('update:mentioned', {
      add: false,
      user: mentionToDelete,
    })
  }
  return newContent
}

function onInputTextarea(value: any) {
  if (props.isOffensiveWarningShow) return
  if (value.inputType === 'deleteContentBackward') {
    inputType.value = value.inputType
  }
  if (
    value.inputType === 'insertCompositionText' &&
    !value.data &&
    value.isComposing &&
    inputType.value
  ) {
    inputType.value = null
  }
  let caretPos = 0

  if (inputRef.value) {
    caretPos = getCaretPos(inputRef.value)
  }
  if (
    ((value.inputType === 'insertText' && !value.data) ||
      value.inputType === 'insertLineBreak' ||
      (value.inputType === 'insertCompositionText' &&
        !value.data &&
        !value.isComposing &&
        inputType.value) ||
      (value.inputType === 'insertCompositionText' &&
        !value.data &&
        value.isComposing &&
        value.target.value.slice(-1) !== ' ' &&
        value.target.value)) &&
    !mention.value
  ) {
    onSend()
    return
  }
  if (
    mention.value &&
    (value.inputType === 'insertLineBreak' ||
      (value.inputType === 'insertText' && !value.data))
  ) {
    return
  }
  let content = value.target.value || ''

  if (oldContent.value.length > content.length) {
    content = deleteMenthionAndriod(content, caretPos)
  }
  if (props.mentionUsers || props.mentionUsers.length) {
    checkMentions(content.slice(0, caretPos))
  }
  oldContent.value = content
  if (!content.length) {
    emit('update:mentioned', {
      add: false,
      user: null,
    })
  }
  validate(content)
  emit('input', content)
}

function onSelectMentionAndroid(user: any) {
  const nanoId = user.id.slice(0, 5)
  const newContent = props.content.replace(
    `@${mention.value}`,
    ` @${user.name}#${nanoId}# `
  )

  emit('update:mentioned', {
    user: { ...user, nanoId },
    add: true,
  })
  validate(newContent)
  emit('input', newContent)
  oldContent.value = newContent
  mention.value = null
}

function onClick() {
  inputRef.value?.focus()
  saveSelection()
}
</script>

<template>
  <validation-provider
    v-bind="validation"
    ref="validationProviderRef"
    :detect-input="false"
    slim
  >
    <div
      v-clickaway="onAwayClick"
      class="relative flex w-full px-[5px]"
      :class="{
        'flex-col-reverse': attachmentsPosition === 'top',
        'flex-col': attachmentsPosition !== 'top',
      }"
    >
      <div v-if="mentionUsers" class="relative">
        <popup :visible="!!mention">
          <rich-input-mentions
            :filter="mention"
            :users="mentionUsers"
            @select="onSelectMention"
          />
        </popup>
      </div>

      <div
        class="flex w-full flex-wrap items-center p-[5px] pl-0"
        :class="{
          'rounded-3xl border pl-[15px]': !plain,
          'border-b': isPost && !isAndriod,
          'pointer-events-none opacity-50': disabled,
          'border-primary': isFocused && !firstError,
          'border-error': firstError,
        }"
      >
        <div
          class="relative mr-2.5 flex w-full max-w-full flex-auto cursor-text flex-col justify-center leading-5 sm:w-auto"
          :class="{
            'w-full': multiline,
          }"
          @click="onClick"
        >
          <div
            v-if="!isAndriod"
            :id="id"
            ref="inputRef"
            contenteditable="true"
            :style="{
              maxHeight: `${maxRows * lineHeight}px`,
              wordBreak: 'break-word',
            }"
            class="relative z-1 inline-block min-w-[5px] overflow-y-auto whitespace-pre-wrap px-px py-0.5 text-base outline-none"
            :class="{
              'mt-[15px]': isPost,
            }"
            v-on="$listeners"
            @keydown="onKeyDown"
            @keyup="onKeyUp"
            @paste="onPaste"
            @focus="onFocus"
            @blur="onBlur"
          />
          <base-textarea
            v-if="isAndriod"
            :id="id"
            :value="content"
            :label="placeholder"
            :focused="focused"
            :error="firstError"
            :maxlength="maxLength"
            :disabled="disabled"
            look="simple"
            @input="onInputTextarea"
          />
          <div
            v-if="!isPost && !content && !isFocused && !isAndriod"
            class="z-back absolute left-0 top-0 px-[15px] py-2.5 text-sm text-eonx-neutral-600"
            style="z-index: -1"
          >
            {{ placeholder }}
          </div>
          <div
            v-if="isShowPlaceholder && isPost && !isAndriod"
            class="z-back absolute left-0 top-0 px-[15px] py-2.5 text-sm transition-all duration-100"
            :class="{
              'text-eonx-neutral-600': !isFocused && !firstError,
              'text-fg-error': !(!isFocused && !firstError),
              'pb-0 pl-0': plain,
              'font-bold text-primary':
                plain && (isFocused || content) && !firstError,
              'font-bold': firstError,
            }"
            :style="getPlaceholderStyle"
          >
            {{ placeholder }}
          </div>
        </div>

        <div
          v-if="!isPost"
          class="flex items-center"
          :class="{
            'mx-auto justify-center': centerActions,
            'ml-auto mr-[15px] justify-end': !centerActions,
            'mt-2.5 sm:mt-0': isAndriod,
          }"
          :style="{
            minHeight: `${lineHeight}px`,
          }"
        >
          <div class="flex self-center text-primary">
            <base-button icon="rec/camera" size="md" @click="onCameraClick" />
            <base-uploader ref="cameraRef" camera @upload="onAddAttachment" />
          </div>
          <div
            v-if="!isAndriod"
            class="relative ml-[15px] flex self-center text-primary"
          >
            <base-button
              icon="rec/happy-face"
              size="md"
              @click="onEmojisClick"
            />
            <base-emojis ref="emojisRef" @select="onEmojiSelect" />
          </div>
          <div class="ml-[15px] flex self-center text-primary">
            <base-button icon="rec/gif" size="lg" @click="onGifsClick" />
            <base-gifs ref="gifsRef" @select="onAddAttachment" />
          </div>
          <div class="ml-[15px] flex self-center text-primary">
            <base-button
              icon="rec/attachment"
              size="md"
              @click="onAttachClick"
            />
            <base-uploader ref="attachRef" multiple @upload="onAddAttachment" />
          </div>
          <div class="ml-[15px] flex self-center text-primary">
            <base-button
              data-test="chat-input-send"
              :class="{
                'cursor-default text-fg-disabled': isSendDisabled,
              }"
              :disabled="isSendDisabled"
              :loading="loading"
              icon="rec/paper-plane"
              size="lg"
              @click="onSend"
            />
          </div>
        </div>
      </div>
      <div
        v-if="firstError && !isAndriod"
        class="mt-[5px] px-[15px] text-xs font-bold text-fg-error"
        :class="{
          'pl-0': isPost,
        }"
      >
        {{ firstError }}
      </div>
      <div
        v-if="maxLength && !isAndriod"
        class="mt-[5px] grow text-right text-sm text-eonx-neutral-600"
      >
        {{ content.length }} / {{ maxLength }}
      </div>

      <template v-if="attachments.length">
        <template v-if="attachmentsPosition === 'popup'">
          <rich-attachments-popup
            ref="attachmentsPopupRef"
            :attachments="attachments"
            @delete="onDeleteAttachment"
          />
          <b
            data-test="chat-input-attachments-button"
            class="block cursor-pointer px-[15px] pb-[5px] text-sm text-primary"
            @click="attachmentsPopupRef.show()"
          >
            {{ attachments.length }}
            {{ attachments.length === 1 ? 'attachment' : 'attachments' }}
          </b>
        </template>

        <div
          v-else
          :class="{
            '-mb-2.5': attachmentsPosition === 'bottom',
            '-mt-2.5': attachmentsPosition === 'top',
          }"
        >
          <div v-if="files.length" class="-mx-2.5 flex flex-wrap">
            <creator-file
              v-for="(file, idx) in files"
              :key="idx"
              :attachment="file"
              removable
              class="m-2.5"
              @delete="onDeleteAttachment"
            />
          </div>
          <div v-if="gifs.length" class="-mx-2.5 flex flex-wrap">
            <creator-gif
              v-for="(gif, idx) in gifs"
              :key="idx"
              :gif="gif"
              removable
              class="m-2.5"
              @delete="onDeleteAttachment"
            />
          </div>
        </div>
      </template>
    </div>
  </validation-provider>
</template>
